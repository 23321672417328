body {
 background-color: rgb(85, 81, 81);
 color: rgb(240, 234, 214);
}
p {
  font-size: 20px;
}

.avatar{
}

.img-center {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}

a:hover {
  color: rgb(240, 234, 214);
  text-decoration: none;
}

hr {
  height:0.2px;
  background-color: rgb(240, 234, 214);
}

.project-link {
  color: rgb(240, 234, 214);
  text-decoration: underline;

}